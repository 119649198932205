// Source:
// Countries with long/lat => https://developers.google.com/public-data/docs/canonical/countries_csv
// Countries images => https://github.com/djaiss/mapsicon
// Total trade volume by country => http://www.cepii.fr/CEPII/en/bdd_modele/bdd_modele_item.asp?id=37 (HS02)
import { flag } from "country-emoji";

const countryCodesWithImage = [
  "ad",
  "ao",
  "ax",
  "bg",
  "bo",
  "by",
  "ch",
  "cr",
  "de",
  "ee",
  "fj",
  "ge",
  "gn",
  "gw",
  "hu",
  "iq",
  "ke",
  "kr",
  "li",
  "ly",
  "mk",
  "ms",
  "mz",
  "nl",
  "pa",
  "pm",
  "re",
  "sc",
  "sk",
  "st",
  "tf",
  "tn",
  "ug",
  "vg",
  "yt",
  "ae",
  "aq",
  "az",
  "bh",
  "bq",
  "bz",
  "ci",
  "cu",
  "dj",
  "eg",
  "fk",
  "gf",
  "gp",
  "gy",
  "id",
  "ir",
  "kg",
  "kw",
  "lk",
  "ma",
  "ml",
  "mt",
  "na",
  "no",
  "pe",
  "pn",
  "ro",
  "sd",
  "sl",
  "sv",
  "tg",
  "to",
  "us",
  "vi",
  "za",
  "af",
  "ar",
  "ba",
  "bi",
  "br",
  "ca",
  "ck",
  "cv",
  "dk",
  "eh",
  "fo",
  "gg",
  "gq",
  "hk",
  "ie",
  "is",
  "kh",
  "ky",
  "lr",
  "mc",
  "mm",
  "mu",
  "nc",
  "np",
  "pf",
  "pr",
  "rs",
  "se",
  "sm",
  "sx",
  "th",
  "tr",
  "uy",
  "vn",
  "zm",
  "ag",
  "as",
  "bb",
  "bj",
  "bs",
  "cc",
  "cl",
  "cw",
  "dm",
  "er",
  "fr",
  "gh",
  "gr",
  "hm",
  "il",
  "it",
  "ki",
  "kz",
  "ls",
  "md",
  "mn",
  "mv",
  "ne",
  "nr",
  "pg",
  "pt",
  "ru",
  "sg",
  "sn",
  "sy",
  "tj",
  "tt",
  "uz",
  "vu",
  "zw",
  "ai",
  "at",
  "bd",
  "bl",
  "bt",
  "cd",
  "cm",
  "cx",
  "do",
  "es",
  "ga",
  "gi",
  "gs",
  "hn",
  "im",
  "jm",
  "km",
  "la",
  "lt",
  "me",
  "mo",
  "mw",
  "nf",
  "nu",
  "ph",
  "pw",
  "rw",
  "sh",
  "so",
  "sz",
  "tk",
  "tw",
  "va",
  "wf",
  "al",
  "au",
  "be",
  "bm",
  "bv",
  "cf",
  "cn",
  "cy",
  "dz",
  "et",
  "gb",
  "gl",
  "gt",
  "hr",
  "in",
  "jo",
  "kn",
  "lb",
  "lu",
  "mf",
  "mq",
  "mx",
  "ng",
  "nz",
  "pk",
  "py",
  "sa",
  "si",
  "sr",
  "tc",
  "tl",
  "tz",
  "vc",
  "ws",
  "am",
  "aw",
  "bf",
  "bn",
  "bw",
  "cg",
  "co",
  "cz",
  "ec",
  "fi",
  "gd",
  "gm",
  "gu",
  "ht",
  "io",
  "jp",
  "kp",
  "lc",
  "lv",
  "mg",
  "mr",
  "my",
  "ni",
  "om",
  "pl",
  "qa",
  "sb",
  "sj",
  "ss",
  "td",
  "tm",
  "ua",
  "ve",
  "ye",
  "tv",
  "mh",
  "fm",
  "mp",
];

export interface Country {
  code: string;
  latitude: number;
  longitude: number;
  name: string;
  total_trade_thousands: number;
}

export const countries: Country[] = [
  {
    code: "AD",
    latitude: 42.546245,
    longitude: 1.601554,
    name: "Andorra",
    total_trade_thousands: 110335,
  },
  {
    code: "AE",
    latitude: 23.424076,
    longitude: 53.847818,
    name: "United Arab Emirates",
    total_trade_thousands: 302413958,
  },
  {
    code: "AF",
    latitude: 33.93911,
    longitude: 67.709953,
    name: "Afghanistan",
    total_trade_thousands: 1888683,
  },
  {
    code: "AG",
    latitude: 17.060816,
    longitude: -61.796428,
    name: "Antigua and Barbuda",
    total_trade_thousands: 208152,
  },
  {
    code: "AI",
    latitude: 18.220554,
    longitude: -63.068615,
    name: "Anguilla",
    total_trade_thousands: 30461,
  },
  {
    code: "AL",
    latitude: 41.153332,
    longitude: 20.168331,
    name: "Albania",
    total_trade_thousands: 3485325,
  },
  {
    code: "AM",
    latitude: 40.069099,
    longitude: 45.038189,
    name: "Armenia",
    total_trade_thousands: 3377591,
  },
  {
    code: "AO",
    latitude: -11.202692,
    longitude: 17.873887,
    name: "Angola",
    total_trade_thousands: 34138634,
  },
  {
    code: "AR",
    latitude: -38.416097,
    longitude: -63.616672,
    name: "Argentina",
    total_trade_thousands: 69429857,
  },
  {
    code: "AS",
    latitude: -14.270972,
    longitude: -170.132217,
    name: "American Samoa",
    total_trade_thousands: 34456,
  },
  {
    code: "AT",
    latitude: 47.516231,
    longitude: 14.550072,
    name: "Austria",
    total_trade_thousands: 194168165,
  },
  {
    code: "AU",
    latitude: -25.274398,
    longitude: 133.775136,
    name: "Australia",
    total_trade_thousands: 349177370,
  },
  {
    code: "AW",
    latitude: 12.52111,
    longitude: -69.968338,
    name: "Aruba",
    total_trade_thousands: 191970,
  },
  {
    code: "AZ",
    latitude: 40.143105,
    longitude: 47.576927,
    name: "Azerbaijan",
    total_trade_thousands: 23602832,
  },
  {
    code: "BA",
    latitude: 43.915886,
    longitude: 17.679076,
    name: "Bosnia and Herzegovina",
    total_trade_thousands: 9519066,
  },
  {
    code: "BB",
    latitude: 13.193887,
    longitude: -59.543198,
    name: "Barbados",
    total_trade_thousands: 364213,
  },
  {
    code: "BD",
    latitude: 23.684994,
    longitude: 90.356331,
    name: "Bangladesh",
    total_trade_thousands: 51770839,
  },
  {
    code: "BE",
    latitude: 50.503887,
    longitude: 4.469936,
    name: "Belgium",
    total_trade_thousands: 419677352,
  },
  {
    code: "BF",
    latitude: 12.238333,
    longitude: -1.561593,
    name: "Burkina Faso",
    total_trade_thousands: 9187647,
  },
  {
    code: "BG",
    latitude: 42.733883,
    longitude: 25.48583,
    name: "Bulgaria",
    total_trade_thousands: 44893042,
  },
  {
    code: "BH",
    latitude: 25.930414,
    longitude: 50.637772,
    name: "Bahrain",
    total_trade_thousands: 14961071,
  },
  {
    code: "BI",
    latitude: -3.373056,
    longitude: 29.918886,
    name: "Burundi",
    total_trade_thousands: 190427,
  },
  {
    code: "BJ",
    latitude: 9.30769,
    longitude: 2.315834,
    name: "Benin",
    total_trade_thousands: 2232449,
  },
  {
    code: "BL",
    latitude: 17.9139222,
    longitude: -62.8338521,
    name: "Saint Barthélemy",
    total_trade_thousands: 4255,
  },
  {
    code: "BM",
    latitude: 32.321384,
    longitude: -64.75737,
    name: "Bermuda",
    total_trade_thousands: 235246,
  },
  {
    code: "BN",
    latitude: 4.535277,
    longitude: 114.727669,
    name: "Brunei",
    total_trade_thousands: 11399941,
  },
  {
    code: "BO",
    latitude: -16.290154,
    longitude: -63.588653,
    name: "Bolivia",
    total_trade_thousands: 11332437,
  },
  {
    code: "BR",
    latitude: -14.235004,
    longitude: -51.92528,
    name: "Brazil",
    total_trade_thousands: 291705501,
  },
  {
    code: "BS",
    latitude: 25.03428,
    longitude: -77.39628,
    name: "Bahamas",
    total_trade_thousands: 1026983,
  },
  {
    code: "BT",
    latitude: 27.514162,
    longitude: 90.433601,
    name: "Bhutan",
    total_trade_thousands: 346105,
  },
  {
    code: "BW",
    latitude: -22.328474,
    longitude: 24.684866,
    name: "Botswana",
    total_trade_thousands: 7514223,
  },
  {
    code: "BY",
    latitude: 53.709807,
    longitude: 27.953389,
    name: "Belarus",
    total_trade_thousands: 34738086,
  },
  {
    code: "BZ",
    latitude: 17.189877,
    longitude: -88.49765,
    name: "Belize",
    total_trade_thousands: 410160,
  },
  {
    code: "CA",
    latitude: 56.130366,
    longitude: -106.346771,
    name: "Canada",
    total_trade_thousands: 475275987,
  },
  {
    code: "CC",
    latitude: -12.164165,
    longitude: 96.870956,
    name: "Cocos Islands",
    total_trade_thousands: 8536,
  },
  {
    code: "CD",
    latitude: -4.038333,
    longitude: 21.758664,
    name: "Democratic Republic of the Congo",
    total_trade_thousands: 16823595,
  },
  {
    code: "CF",
    latitude: 6.611111,
    longitude: 20.939444,
    name: "Central African Republic",
    total_trade_thousands: 122662,
  },
  {
    code: "CG",
    latitude: -0.228021,
    longitude: 15.827659,
    name: "Congo",
    total_trade_thousands: 7089295,
  },
  {
    code: "CH",
    latitude: 46.818188,
    longitude: 8.227512,
    name: "Switzerland",
    total_trade_thousands: 360984931,
  },
  {
    code: "CI",
    latitude: 7.539989,
    longitude: -5.54708,
    name: "Côte d'Ivoire",
    total_trade_thousands: 12414401,
  },
  {
    code: "CK",
    latitude: -21.236736,
    longitude: -159.777671,
    name: "Cook Islands",
    total_trade_thousands: 31441,
  },
  {
    code: "CL",
    latitude: -35.675147,
    longitude: -71.542969,
    name: "Chile",
    total_trade_thousands: 95873369,
  },
  {
    code: "CM",
    latitude: 7.369722,
    longitude: 12.354722,
    name: "Cameroon",
    total_trade_thousands: 4943151,
  },
  {
    code: "CN",
    latitude: 35.86166,
    longitude: 104.195397,
    name: "China",
    total_trade_thousands: 3333679648,
  },
  {
    code: "CO",
    latitude: 4.570868,
    longitude: -74.297333,
    name: "Colombia",
    total_trade_thousands: 42931740,
  },
  {
    code: "CR",
    latitude: 9.748917,
    longitude: -83.753428,
    name: "Costa Rica",
    total_trade_thousands: 17574083,
  },
  {
    code: "CU",
    latitude: 21.521757,
    longitude: -77.781167,
    name: "Cuba",
    total_trade_thousands: 1090672,
  },
  {
    code: "CV",
    latitude: 16.002082,
    longitude: -24.013197,
    name: "Cape Verde",
    total_trade_thousands: 122983,
  },
  {
    code: "CW",
    latitude: 12.11666,
    longitude: -68.9333,
    name: "Curaçao",
    total_trade_thousands: 326194,
  },
  {
    code: "CX",
    latitude: -10.447525,
    longitude: 105.690449,
    name: "Christmas Island",
    total_trade_thousands: 13401,
  },
  {
    code: "CY",
    latitude: 35.126413,
    longitude: 33.429859,
    name: "Cyprus",
    total_trade_thousands: 5348803,
  },
  {
    code: "CZ",
    latitude: 49.817492,
    longitude: 15.472962,
    name: "Czechia",
    total_trade_thousands: 215445315,
  },
  {
    code: "DE",
    latitude: 51.165691,
    longitude: 10.451526,
    name: "Germany",
    total_trade_thousands: 1551763185,
  },
  {
    code: "DJ",
    latitude: 11.825138,
    longitude: 42.590275,
    name: "Djibouti",
    total_trade_thousands: 329053,
  },
  {
    code: "DK",
    latitude: 56.26392,
    longitude: 9.501785,
    name: "Denmark",
    total_trade_thousands: 118526090,
  },
  {
    code: "DM",
    latitude: 15.414999,
    longitude: -61.370976,
    name: "Dominica",
    total_trade_thousands: 30847,
  },
  {
    code: "DO",
    latitude: 18.735693,
    longitude: -70.162651,
    name: "Dominican Republic",
    total_trade_thousands: 12904388,
  },
  {
    code: "DZ",
    latitude: 28.033886,
    longitude: 1.659626,
    name: "Algeria",
    total_trade_thousands: 35368145,
  },
  {
    code: "EC",
    latitude: -1.831239,
    longitude: -78.183406,
    name: "Ecuador",
    total_trade_thousands: 28241037,
  },
  {
    code: "EE",
    latitude: 58.595272,
    longitude: 25.013607,
    name: "Estonia",
    total_trade_thousands: 21034025,
  },
  {
    code: "EG",
    latitude: 26.820553,
    longitude: 30.802498,
    name: "Egypt",
    total_trade_thousands: 46136261,
  },
  {
    code: "ER",
    latitude: 15.179384,
    longitude: 39.782334,
    name: "Eritrea",
    total_trade_thousands: 623389,
  },
  {
    code: "ES",
    latitude: 40.463667,
    longitude: -3.74922,
    name: "Spain",
    total_trade_thousands: 373527727,
  },
  {
    code: "ET",
    latitude: 9.145,
    longitude: 40.489673,
    name: "Ethiopia",
    total_trade_thousands: 4475595,
  },
  {
    code: "FI",
    latitude: 61.92411,
    longitude: 25.748151,
    name: "Finland",
    total_trade_thousands: 83704904,
  },
  {
    code: "FJ",
    latitude: -16.578193,
    longitude: 179.414413,
    name: "Fiji",
    total_trade_thousands: 1094396,
  },
  {
    code: "FK",
    latitude: -51.796253,
    longitude: -59.523613,
    name: "Falkland Islands (Malvinas)",
    total_trade_thousands: 308154,
  },
  {
    code: "FM",
    latitude: 7.425554,
    longitude: 150.550812,
    name: "Micronesia",
    total_trade_thousands: 179283,
  },
  {
    code: "FR",
    latitude: 46.227638,
    longitude: 2.213749,
    name: "France",
    total_trade_thousands: 576187418,
  },
  {
    code: "GA",
    latitude: -0.803689,
    longitude: 11.609444,
    name: "Gabon",
    total_trade_thousands: 5976503,
  },
  {
    code: "GB",
    latitude: 55.378051,
    longitude: -3.435973,
    name: "United Kingdom",
    total_trade_thousands: 418511340,
  },
  {
    code: "GD",
    latitude: 12.262776,
    longitude: -61.604171,
    name: "Grenada",
    total_trade_thousands: 35940,
  },
  {
    code: "GE",
    latitude: 42.315407,
    longitude: 43.356892,
    name: "Georgia",
    total_trade_thousands: 5156026,
  },
  {
    code: "GH",
    latitude: 7.946527,
    longitude: -1.023194,
    name: "Ghana",
    total_trade_thousands: 14104822,
  },
  {
    code: "GI",
    latitude: 36.137741,
    longitude: -5.345374,
    name: "Gibraltar",
    total_trade_thousands: 123669,
  },
  {
    code: "GL",
    latitude: 71.706936,
    longitude: -42.604303,
    name: "Greenland",
    total_trade_thousands: 1384414,
  },
  {
    code: "GM",
    latitude: 13.443182,
    longitude: -15.310139,
    name: "Gambia",
    total_trade_thousands: 153083,
  },
  {
    code: "GN",
    latitude: 9.945587,
    longitude: -9.696645,
    name: "Guinea",
    total_trade_thousands: 9537615,
  },
  {
    code: "GQ",
    latitude: 1.650801,
    longitude: 10.267895,
    name: "Equatorial Guinea",
    total_trade_thousands: 4526777,
  },
  {
    code: "GR",
    latitude: 39.074208,
    longitude: 21.824312,
    name: "Greece",
    total_trade_thousands: 68572886,
  },
  {
    code: "GT",
    latitude: 15.783471,
    longitude: -90.230759,
    name: "Guatemala",
    total_trade_thousands: 14305080,
  },
  {
    code: "GW",
    latitude: 11.803749,
    longitude: -15.180413,
    name: "Guinea-Bissau",
    total_trade_thousands: 168736,
  },
  {
    code: "GY",
    latitude: 4.860416,
    longitude: -58.93018,
    name: "Guyana",
    total_trade_thousands: 5130372,
  },
  {
    code: "HK",
    latitude: 22.396428,
    longitude: 114.109497,
    name: "Hong Kong",
    total_trade_thousands: 117406315,
  },
  {
    code: "HN",
    latitude: 15.199999,
    longitude: -86.241905,
    name: "Honduras",
    total_trade_thousands: 10131373,
  },
  {
    code: "HR",
    latitude: 45.1,
    longitude: 15.2,
    name: "Croatia",
    total_trade_thousands: 22187881,
  },
  {
    code: "HT",
    latitude: 18.971187,
    longitude: -72.285215,
    name: "Haiti",
    total_trade_thousands: 1359296,
  },
  {
    code: "HU",
    latitude: 47.162494,
    longitude: 19.503304,
    name: "Hungary",
    total_trade_thousands: 135850064,
  },
  {
    code: "ID",
    latitude: -0.789275,
    longitude: 113.921327,
    name: "Indonesia",
    total_trade_thousands: 255218718,
  },
  {
    code: "IE",
    latitude: 53.41291,
    longitude: -8.24389,
    name: "Ireland",
    total_trade_thousands: 218973192,
  },
  {
    code: "IL",
    latitude: 31.046051,
    longitude: 34.851612,
    name: "Israel",
    total_trade_thousands: 67268044,
  },
  {
    code: "IN",
    latitude: 20.593684,
    longitude: 78.96288,
    name: "India",
    total_trade_thousands: 400697785,
  },
  {
    code: "IQ",
    latitude: 33.223191,
    longitude: 43.679291,
    name: "Iraq",
    total_trade_thousands: 81348628,
  },
  {
    code: "IR",
    latitude: 32.427908,
    longitude: 53.688046,
    name: "Iran",
    total_trade_thousands: 13988752,
  },
  {
    code: "IS",
    latitude: 64.963051,
    longitude: -19.020835,
    name: "Iceland",
    total_trade_thousands: 7080233,
  },
  {
    code: "IT",
    latitude: 41.87194,
    longitude: 12.56738,
    name: "Italy",
    total_trade_thousands: 596803213,
  },
  {
    code: "JM",
    latitude: 18.109581,
    longitude: -77.297508,
    name: "Jamaica",
    total_trade_thousands: 1409755,
  },
  {
    code: "JO",
    latitude: 30.585164,
    longitude: 36.238414,
    name: "Jordan",
    total_trade_thousands: 12561497,
  },
  {
    code: "JP",
    latitude: 36.204824,
    longitude: 138.252924,
    name: "Japan",
    total_trade_thousands: 776875075,
  },
  {
    code: "KE",
    latitude: -0.023559,
    longitude: 37.906193,
    name: "Kenya",
    total_trade_thousands: 7303491,
  },
  {
    code: "KG",
    latitude: 41.20438,
    longitude: 74.766098,
    name: "Kyrgyzstan",
    total_trade_thousands: 2965460,
  },
  {
    code: "KH",
    latitude: 12.565679,
    longitude: 104.990963,
    name: "Cambodia",
    total_trade_thousands: 29234557,
  },
  {
    code: "KI",
    latitude: -3.370417,
    longitude: -168.734039,
    name: "Kiribati",
    total_trade_thousands: 94751,
  },
  {
    code: "KM",
    latitude: -11.875001,
    longitude: 43.872219,
    name: "Comoros",
    total_trade_thousands: 61831,
  },
  {
    code: "KN",
    latitude: 17.357822,
    longitude: -62.782998,
    name: "Saint Kitts and Nevis",
    total_trade_thousands: 63546,
  },
  {
    code: "KP",
    latitude: 40.339852,
    longitude: 127.510093,
    name: "North Korea",
    total_trade_thousands: 180797,
  },
  {
    code: "KR",
    latitude: 35.907757,
    longitude: 127.766922,
    name: "South Korea",
    total_trade_thousands: 684036497,
  },
  {
    code: "KW",
    latitude: 29.31166,
    longitude: 47.481766,
    name: "Kuwait",
    total_trade_thousands: 59407948,
  },
  {
    code: "KY",
    latitude: 19.513469,
    longitude: -80.566956,
    name: "Cayman Islands",
    total_trade_thousands: 2429081,
  },
  {
    code: "KZ",
    latitude: 48.019573,
    longitude: 66.923684,
    name: "Kazakhstan",
    total_trade_thousands: 54072178,
  },
  {
    code: "LA",
    latitude: 19.85627,
    longitude: 102.495496,
    name: "Laos",
    total_trade_thousands: 9529856,
  },
  {
    code: "LB",
    latitude: 33.854721,
    longitude: 35.862285,
    name: "Lebanon",
    total_trade_thousands: 4852966,
  },
  {
    code: "LC",
    latitude: 13.909444,
    longitude: -60.978893,
    name: "Saint Lucia",
    total_trade_thousands: 2749418,
  },
  {
    code: "LK",
    latitude: 7.873054,
    longitude: 80.771797,
    name: "Sri Lanka",
    total_trade_thousands: 14066174,
  },
  {
    code: "LR",
    latitude: 6.428055,
    longitude: -9.429499,
    name: "Liberia",
    total_trade_thousands: 1587105,
  },
  {
    code: "LS",
    latitude: -29.609988,
    longitude: 28.233608,
    name: "Lesotho",
    total_trade_thousands: 1067680,
  },
  {
    code: "LT",
    latitude: 55.169438,
    longitude: 23.881275,
    name: "Lithuania",
    total_trade_thousands: 38891139,
  },
  {
    code: "LU",
    latitude: 49.815273,
    longitude: 6.129583,
    name: "Luxembourg",
    total_trade_thousands: 19970720,
  },
  {
    code: "LV",
    latitude: 56.879635,
    longitude: 24.603189,
    name: "Latvia",
    total_trade_thousands: 19497323,
  },
  {
    code: "LY",
    latitude: 26.3351,
    longitude: 17.228331,
    name: "Libya",
    total_trade_thousands: 30853652,
  },
  {
    code: "MA",
    latitude: 31.791702,
    longitude: -7.09262,
    name: "Morocco",
    total_trade_thousands: 43398710,
  },
  {
    code: "MD",
    latitude: 47.411631,
    longitude: 28.369885,
    name: "Moldova",
    total_trade_thousands: 3881576,
  },
  {
    code: "ME",
    latitude: 42.708678,
    longitude: 19.37439,
    name: "Montenegro",
    total_trade_thousands: 833338,
  },
  {
    code: "MG",
    latitude: -18.766947,
    longitude: 46.869107,
    name: "Madagascar",
    total_trade_thousands: 3338894,
  },
  {
    code: "MH",
    latitude: 7.131474,
    longitude: 171.184478,
    name: "Marshall Islands",
    total_trade_thousands: 1379803,
  },
  {
    code: "MK",
    latitude: 41.608635,
    longitude: 21.745275,
    name: "Macedonia",
    total_trade_thousands: 8816554,
  },
  {
    code: "ML",
    latitude: 17.570692,
    longitude: -3.996166,
    name: "Mali",
    total_trade_thousands: 9380067,
  },
  {
    code: "MM",
    latitude: 21.913965,
    longitude: 95.956223,
    name: "Myanmar",
    total_trade_thousands: 20374334,
  },
  {
    code: "MN",
    latitude: 46.862496,
    longitude: 103.846656,
    name: "Mongolia",
    total_trade_thousands: 10336995,
  },
  {
    code: "MO",
    latitude: 22.198745,
    longitude: 113.543873,
    name: "Macau",
    total_trade_thousands: 1747771,
  },
  {
    code: "MP",
    latitude: 17.33083,
    longitude: 145.38469,
    name: "Northern Mariana Islands",
    total_trade_thousands: 3164,
  },
  {
    code: "MR",
    latitude: 21.00789,
    longitude: -10.940835,
    name: "Mauritania",
    total_trade_thousands: 4199592,
  },
  {
    code: "MS",
    latitude: 16.742498,
    longitude: -62.187366,
    name: "Montserrat",
    total_trade_thousands: 11945,
  },
  {
    code: "MT",
    latitude: 35.937496,
    longitude: 14.375416,
    name: "Malta",
    total_trade_thousands: 5586001,
  },
  {
    code: "MU",
    latitude: -20.348404,
    longitude: 57.552152,
    name: "Mauritius",
    total_trade_thousands: 2307567,
  },
  {
    code: "MV",
    latitude: 3.202778,
    longitude: 73.22068,
    name: "Maldives",
    total_trade_thousands: 245674,
  },
  {
    code: "MW",
    latitude: -13.254308,
    longitude: 34.301525,
    name: "Malawi",
    total_trade_thousands: 954558,
  },
  {
    code: "MX",
    latitude: 23.634501,
    longitude: -102.552784,
    name: "Mexico",
    total_trade_thousands: 485371896,
  },
  {
    code: "MY",
    latitude: 4.040255,
    longitude: 109.091912,
    name: "Malaysia",
    total_trade_thousands: 373567393,
  },
  {
    code: "MZ",
    latitude: -18.665695,
    longitude: 35.529562,
    name: "Mozambique",
    total_trade_thousands: 8786089,
  },
  {
    code: "NC",
    latitude: -20.904305,
    longitude: 165.618042,
    name: "New Caledonia",
    total_trade_thousands: 1928432,
  },
  {
    code: "NE",
    latitude: 17.607789,
    longitude: 8.081666,
    name: "Niger",
    total_trade_thousands: 3834432,
  },
  {
    code: "NF",
    latitude: -29.040835,
    longitude: 167.954712,
    name: "Norfolk Island",
    total_trade_thousands: 1979,
  },
  {
    code: "NG",
    latitude: 9.081999,
    longitude: 8.675277,
    name: "Nigeria",
    total_trade_thousands: 58120860,
  },
  {
    code: "NI",
    latitude: 12.865416,
    longitude: -85.207229,
    name: "Nicaragua",
    total_trade_thousands: 7159276,
  },
  {
    code: "NL",
    latitude: 52.132633,
    longitude: 5.291266,
    name: "Netherlands",
    total_trade_thousands: 627699966,
  },
  {
    code: "NO",
    latitude: 60.472024,
    longitude: 8.468946,
    name: "Norway",
    total_trade_thousands: 160971902,
  },
  {
    code: "NP",
    latitude: 28.394857,
    longitude: 84.124008,
    name: "Nepal",
    total_trade_thousands: 1727331,
  },
  {
    code: "NR",
    latitude: -0.522778,
    longitude: 166.931503,
    name: "Nauru",
    total_trade_thousands: 187557,
  },
  {
    code: "NU",
    latitude: -19.054445,
    longitude: -169.867233,
    name: "Niue",
    total_trade_thousands: 5713,
  },
  {
    code: "NZ",
    latitude: -40.900557,
    longitude: 174.885971,
    name: "New Zealand",
    total_trade_thousands: 61528306,
  },
  {
    code: "OM",
    latitude: 21.512583,
    longitude: 55.923255,
    name: "Oman",
    total_trade_thousands: 58499544,
  },
  {
    code: "PA",
    latitude: 8.537981,
    longitude: -80.782127,
    name: "Panama",
    total_trade_thousands: 6759631,
  },
  {
    code: "PE",
    latitude: -9.189967,
    longitude: -75.015152,
    name: "Peru",
    total_trade_thousands: 57690184,
  },
  {
    code: "PF",
    latitude: -17.679742,
    longitude: -149.406843,
    name: "French Polynesia",
    total_trade_thousands: 161903,
  },
  {
    code: "PG",
    latitude: -6.314993,
    longitude: 143.95555,
    name: "Papua New Guinea",
    total_trade_thousands: 10973022,
  },
  {
    code: "PH",
    latitude: 12.879721,
    longitude: 121.774017,
    name: "Philippines",
    total_trade_thousands: 101326385,
  },
  {
    code: "PK",
    latitude: 30.375321,
    longitude: 69.345116,
    name: "Pakistan",
    total_trade_thousands: 32981958,
  },
  {
    code: "PL",
    latitude: 51.919438,
    longitude: 19.145136,
    name: "Poland",
    total_trade_thousands: 314731737,
  },
  {
    code: "PM",
    latitude: 46.941936,
    longitude: -56.27111,
    name: "Saint Pierre and Miquelon",
    total_trade_thousands: 8848,
  },
  {
    code: "PN",
    latitude: -24.703615,
    longitude: -127.439308,
    name: "Pitcairn Islands",
    total_trade_thousands: 6293,
  },
  {
    code: "PT",
    latitude: 39.399872,
    longitude: -8.224454,
    name: "Portugal",
    total_trade_thousands: 74917411,
  },
  {
    code: "PW",
    latitude: 7.51498,
    longitude: 134.58252,
    name: "Palau",
    total_trade_thousands: 5305,
  },
  {
    code: "PY",
    latitude: -23.442503,
    longitude: -58.443832,
    name: "Paraguay",
    total_trade_thousands: 10773357,
  },
  {
    code: "QA",
    latitude: 25.354826,
    longitude: 51.183884,
    name: "Qatar",
    total_trade_thousands: 96719716,
  },
  {
    code: "RO",
    latitude: 45.943161,
    longitude: 24.96676,
    name: "Romania",
    total_trade_thousands: 90902742,
  },
  {
    code: "RS",
    latitude: 44.016521,
    longitude: 21.005859,
    name: "Serbia",
    total_trade_thousands: 27270409,
  },
  {
    code: "RU",
    latitude: 61.52401,
    longitude: 105.318756,
    name: "Russia",
    total_trade_thousands: 490357948,
  },
  {
    code: "RW",
    latitude: -1.940278,
    longitude: 29.873888,
    name: "Rwanda",
    total_trade_thousands: 1584332,
  },
  {
    code: "SA",
    latitude: 23.885942,
    longitude: 45.079162,
    name: "Saudi Arabia",
    total_trade_thousands: 252833338,
  },
  {
    code: "SB",
    latitude: -9.64571,
    longitude: 160.156194,
    name: "Solomon Islands",
    total_trade_thousands: 479550,
  },
  {
    code: "SC",
    latitude: -4.679574,
    longitude: 55.491977,
    name: "Seychelles",
    total_trade_thousands: 2270062,
  },
  {
    code: "SD",
    latitude: 15.8905231,
    longitude: 30.7671933,
    name: "Sudan",
    total_trade_thousands: 5441987,
  },
  {
    code: "SS",
    latitude: 6.85065,
    longitude: 30.659016,
    name: "South Sudan",
    total_trade_thousands: 563821,
  },
  {
    code: "SE",
    latitude: 60.128161,
    longitude: 18.643501,
    name: "Sweden",
    total_trade_thousands: 182730076,
  },
  {
    code: "SG",
    latitude: 1.352083,
    longitude: 103.819836,
    name: "Singapore",
    total_trade_thousands: 335424173,
  },
  {
    code: "SH",
    latitude: -24.143474,
    longitude: -10.030696,
    name: "Saint Helena",
    total_trade_thousands: 37510,
  },
  {
    code: "SI",
    latitude: 46.151241,
    longitude: 14.995463,
    name: "Slovenia",
    total_trade_thousands: 44380693,
  },
  {
    code: "SK",
    latitude: 48.669026,
    longitude: 19.699024,
    name: "Slovakia",
    total_trade_thousands: 103629693,
  },
  {
    code: "SL",
    latitude: 8.460555,
    longitude: -11.779889,
    name: "Sierra Leone",
    total_trade_thousands: 878155,
  },
  {
    code: "SM",
    latitude: 43.94236,
    longitude: 12.457777,
    name: "San Marino",
    total_trade_thousands: 221289,
  },
  {
    code: "SN",
    latitude: 14.497401,
    longitude: -14.452362,
    name: "Senegal",
    total_trade_thousands: 5337630,
  },
  {
    code: "SO",
    latitude: 5.152149,
    longitude: 46.199616,
    name: "Somalia",
    total_trade_thousands: 481116,
  },
  {
    code: "SR",
    latitude: 3.919305,
    longitude: -56.027783,
    name: "Suriname",
    total_trade_thousands: 2872435,
  },
  {
    code: "ST",
    latitude: 0.18636,
    longitude: 6.613081,
    name: "São Tomé and Príncipe",
    total_trade_thousands: 40147,
  },
  {
    code: "SV",
    latitude: 13.794185,
    longitude: -88.89653,
    name: "El Salvador",
    total_trade_thousands: 6692823,
  },
  {
    code: "SY",
    latitude: 34.802075,
    longitude: 38.996815,
    name: "Syria",
    total_trade_thousands: 1005658,
  },
  {
    code: "SZ",
    latitude: -26.522503,
    longitude: 31.465866,
    name: "Eswatini",
    total_trade_thousands: 2489504,
  },
  {
    code: "TC",
    latitude: 21.694025,
    longitude: -71.797928,
    name: "Turks and Caicos Islands",
    total_trade_thousands: 7811,
  },
  {
    code: "TD",
    latitude: 15.454166,
    longitude: 18.732207,
    name: "Chad",
    total_trade_thousands: 2731249,
  },
  {
    code: "TG",
    latitude: 8.619543,
    longitude: 0.824782,
    name: "Togo",
    total_trade_thousands: 3549828,
  },
  {
    code: "TH",
    latitude: 15.870032,
    longitude: 100.992541,
    name: "Thailand",
    total_trade_thousands: 303322746,
  },
  {
    code: "TJ",
    latitude: 38.861034,
    longitude: 71.276093,
    name: "Tajikistan",
    total_trade_thousands: 2133813,
  },
  {
    code: "TK",
    latitude: -8.967363,
    longitude: -171.855881,
    name: "Tokelau",
    total_trade_thousands: 23976,
  },
  {
    code: "TL",
    latitude: -8.874217,
    longitude: 125.727539,
    name: "Timor-Leste",
    total_trade_thousands: 297125,
  },
  {
    code: "TM",
    latitude: 38.969719,
    longitude: 59.556278,
    name: "Turkmenistan",
    total_trade_thousands: 7253214,
  },
  {
    code: "TN",
    latitude: 33.886917,
    longitude: 9.537499,
    name: "Tunisia",
    total_trade_thousands: 19454801,
  },
  {
    code: "TO",
    latitude: -21.178986,
    longitude: -175.198242,
    name: "Tonga",
    total_trade_thousands: 14765,
  },
  {
    code: "TR",
    latitude: 38.963745,
    longitude: 35.243322,
    name: "Turkey",
    total_trade_thousands: 239127579,
  },
  {
    code: "TT",
    latitude: 10.691803,
    longitude: -61.222503,
    name: "Trinidad and Tobago",
    total_trade_thousands: 10940189,
  },
  {
    code: "TV",
    latitude: -7.109535,
    longitude: 177.64933,
    name: "Tuvalu",
    total_trade_thousands: 129808,
  },
  {
    code: "TZ",
    latitude: -6.369028,
    longitude: 34.888822,
    name: "Tanzania",
    total_trade_thousands: 9104912,
  },
  {
    code: "UA",
    latitude: 48.379433,
    longitude: 31.16558,
    name: "Ukraine",
    total_trade_thousands: 69601983,
  },
  {
    code: "UG",
    latitude: 1.373333,
    longitude: 32.290275,
    name: "Uganda",
    total_trade_thousands: 3561538,
  },
  {
    code: "US",
    latitude: 37.09024,
    longitude: -95.712891,
    name: "United States",
    total_trade_thousands: 1633748056,
  },
  {
    code: "UY",
    latitude: -32.522779,
    longitude: -55.765835,
    name: "Uruguay",
    total_trade_thousands: 10557906,
  },
  {
    code: "UZ",
    latitude: 41.377491,
    longitude: 64.585262,
    name: "Uzbekistan",
    total_trade_thousands: 14687657,
  },
  {
    code: "VC",
    latitude: 12.984305,
    longitude: -61.287228,
    name: "Saint Vincent and the Grenadines",
    total_trade_thousands: 121527,
  },
  {
    code: "VE",
    latitude: 6.42375,
    longitude: -66.58973,
    name: "Venezuela",
    total_trade_thousands: 3214022,
  },
  {
    code: "VG",
    latitude: 18.420695,
    longitude: -64.639968,
    name: "British Virgin Islands",
    total_trade_thousands: 253551,
  },
  {
    code: "VN",
    latitude: 14.058324,
    longitude: 108.277199,
    name: "Vietnam",
    total_trade_thousands: 380364422,
  },
  {
    code: "VU",
    latitude: -15.376706,
    longitude: 166.959158,
    name: "Vanuatu",
    total_trade_thousands: 270583,
  },
  {
    code: "WF",
    latitude: -13.768752,
    longitude: -177.156097,
    name: "Wallis and Futuna",
    total_trade_thousands: 465,
  },
  {
    code: "WS",
    latitude: -13.759029,
    longitude: -172.104629,
    name: "Samoa",
    total_trade_thousands: 60005,
  },
  {
    code: "YE",
    latitude: 15.552727,
    longitude: 48.516388,
    name: "Yemen",
    total_trade_thousands: 1976514,
  },
  {
    code: "ZA",
    latitude: -30.559482,
    longitude: 22.937506,
    name: "South Africa",
    total_trade_thousands: 149107429,
  },
  {
    code: "ZM",
    latitude: -13.133897,
    longitude: 27.849332,
    name: "Zambia",
    total_trade_thousands: 13520843,
  },
  {
    code: "ZW",
    latitude: -19.015438,
    longitude: 29.154857,
    name: "Zimbabwe",
    total_trade_thousands: 7581325,
  },
  {
    code: "BQ",
    latitude: 12.103832918,
    longitude: -68.283498866,
    name: "Bonaire",
    total_trade_thousands: 74807,
  },
];

export const fictionalCountries: Country[] = [
  {
    code: "AA",
    latitude: 12.546245,
    longitude: 1.601554,
    name: "Atlantis",
    total_trade_thousands: 1337,
  },
  {
    code: "AB",
    latitude: 12.546245,
    longitude: 1.601554,
    name: "Central City",
    total_trade_thousands: 1337,
  },
  {
    code: "AC",
    latitude: 12.546245,
    longitude: 1.601554,
    name: "Cerulean City",
    total_trade_thousands: 1337,
  },
  {
    code: "AD",
    latitude: 12.546245,
    longitude: 1.601554,
    name: "Deepheart Valley",
    total_trade_thousands: 1337,
  },
  {
    code: "AE",
    latitude: 12.546245,
    longitude: 1.601554,
    name: "Friendship Island",
    total_trade_thousands: 1337,
  },
  {
    code: "AF",
    latitude: 12.546245,
    longitude: 1.601554,
    name: "Gallifrey",
    total_trade_thousands: 1337,
  },
  {
    code: "AG",
    latitude: 12.546245,
    longitude: 1.601554,
    name: "Gotham",
    total_trade_thousands: 1337,
  },
  {
    code: "AH",
    latitude: 12.546245,
    longitude: 1.601554,
    name: "Green Hills",
    total_trade_thousands: 1337,
  },
  {
    code: "AI",
    latitude: 12.546245,
    longitude: 1.601554,
    name: "Hill Valley",
    total_trade_thousands: 1337,
  },
  {
    code: "AJ",
    latitude: 42.546245,
    longitude: 1.601554,
    name: "Land of Oz",
    total_trade_thousands: 1337,
  },
  {
    code: "AK",
    latitude: 12.546245,
    longitude: 1.601554,
    name: "Liberty City",
    total_trade_thousands: 1337,
  },
  {
    code: "AL",
    latitude: 12.546245,
    longitude: 1.601554,
    name: "Metropolis",
    total_trade_thousands: 1337,
  },
  {
    code: "AM",
    latitude: 12.546245,
    longitude: 1.601554,
    name: "Mos Eisley",
    total_trade_thousands: 1337,
  },
  {
    code: "AN",
    latitude: 12.546245,
    longitude: 1.601554,
    name: "Mushroom Kingdom",
    total_trade_thousands: 1337,
  },
  {
    code: "AO",
    latitude: 12.546245,
    longitude: 1.601554,
    name: "Narnia",
    total_trade_thousands: 1337,
  },
  {
    code: "AP",
    latitude: 12.546245,
    longitude: 1.601554,
    name: "Pelotillehue",
    total_trade_thousands: 1337,
  },
  {
    code: "AQ",
    latitude: 12.546245,
    longitude: 1.601554,
    name: "Phantom Island",
    total_trade_thousands: 1337,
  },
  {
    code: "AR",
    latitude: 12.546245,
    longitude: 1.601554,
    name: "Pleasantville",
    total_trade_thousands: 1337,
  },
  {
    code: "AS",
    latitude: 12.546245,
    longitude: 1.601554,
    name: "Raccoon City",
    total_trade_thousands: 1337,
  },
  {
    code: "AT",
    latitude: 12.546245,
    longitude: 1.601554,
    name: "San Andreas",
    total_trade_thousands: 1337,
  },
  {
    code: "AU",
    latitude: 12.546245,
    longitude: 1.601554,
    name: "Smallville",
    total_trade_thousands: 1337,
  },
  {
    code: "AV",
    latitude: 12.546245,
    longitude: 1.601554,
    name: "Springfield",
    total_trade_thousands: 1337,
  },
  {
    code: "AW",
    latitude: 12.546245,
    longitude: 1.601554,
    name: "The Shire",
    total_trade_thousands: 1337,
  },
  {
    code: "AX",
    latitude: 12.546245,
    longitude: 1.601554,
    name: "Twin Peaks",
    total_trade_thousands: 1337,
  },
  {
    code: "AY",
    latitude: 12.546245,
    longitude: 1.601554,
    name: "Vice City",
    total_trade_thousands: 1337,
  },
  {
    code: "AZ",
    latitude: 12.546245,
    longitude: 1.601554,
    name: "Wakanda",
    total_trade_thousands: 1337,
  },
  {
    code: "BA",
    latitude: 12.546245,
    longitude: 1.601554,
    name: "Westeros",
    total_trade_thousands: 1337,
  },
  {
    code: "BB",
    latitude: 12.546245,
    longitude: 1.601554,
    name: "Westview",
    total_trade_thousands: 1337,
  },
  {
    code: "BC",
    latitude: 12.546245,
    longitude: 1.601554,
    name: "Westworld",
    total_trade_thousands: 1337,
  },
  {
    code: "BD",
    latitude: 12.546245,
    longitude: 1.601554,
    name: "Winterfell",
    total_trade_thousands: 1337,
  },
  {
    code: "BE",
    latitude: 12.546245,
    longitude: 1.601554,
    name: "Zion",
    total_trade_thousands: 1337,
  },
];

export const countriesWithImage = countries.filter((c) =>
  countryCodesWithImage.includes(c.code.toLowerCase())
);

export function getCountryName(language: string, country: Country | undefined) {
  return country?.name;
}

export function sanitizeCountryName(countryName: string | undefined): string {
  return countryName
    ? countryName
        .normalize("NFD")
        .replace(/[\u0300-\u036f]/g, "")
        .replace(/[- '()]/g, "")
        .toLowerCase()
    : "";
}

export interface Iso {
  [id: string]: string;
}

export const countryISOMapping: Iso = {
  AF: "AFG",
  AX: "ALA",
  AL: "ALB",
  DZ: "DZA",
  AS: "ASM",
  AD: "AND",
  AO: "AGO",
  AI: "AIA",
  AQ: "ATA",
  AG: "ATG",
  AR: "ARG",
  AM: "ARM",
  AW: "ABW",
  AU: "AUS",
  AT: "AUT",
  AZ: "AZE",
  BS: "BHS",
  BH: "BHR",
  BD: "BGD",
  BB: "BRB",
  BQ: "BES",
  BY: "BLR",
  BE: "BEL",
  BZ: "BLZ",
  BJ: "BEN",
  BM: "BMU",
  BT: "BTN",
  BO: "BOL",
  BA: "BIH",
  BW: "BWA",
  BV: "BVT",
  BR: "BRA",
  VG: "VGB",
  IO: "IOT",
  BN: "BRN",
  BG: "BGR",
  BF: "BFA",
  BI: "BDI",
  KH: "KHM",
  CM: "CMR",
  CA: "CAN",
  CV: "CPV",
  CW: "CUW",
  KY: "CYM",
  CF: "CAF",
  TD: "TCD",
  CL: "CHL",
  CN: "CHN",
  HK: "HKG",
  MO: "MAC",
  CX: "CXR",
  CC: "CCK",
  CO: "COL",
  KM: "COM",
  CG: "COG",
  CD: "COD",
  CK: "COK",
  CR: "CRI",
  CI: "CIV",
  HR: "HRV",
  CU: "CUB",
  CY: "CYP",
  CZ: "CZE",
  DK: "DNK",
  DJ: "DJI",
  DM: "DMA",
  DO: "DOM",
  EC: "ECU",
  EG: "EGY",
  SV: "SLV",
  GQ: "GNQ",
  ER: "ERI",
  EE: "EST",
  ET: "ETH",
  FK: "FLK",
  FO: "FRO",
  FJ: "FJI",
  FI: "FIN",
  FR: "FRA",
  GF: "GUF",
  PF: "PYF",
  TF: "ATF",
  GA: "GAB",
  GM: "GMB",
  GE: "GEO",
  DE: "DEU",
  GH: "GHA",
  GI: "GIB",
  GR: "GRC",
  GL: "GRL",
  GD: "GRD",
  GP: "GLP",
  GU: "GUM",
  GT: "GTM",
  GG: "GGY",
  GN: "GIN",
  GW: "GNB",
  GY: "GUY",
  HT: "HTI",
  HM: "HMD",
  VA: "VAT",
  HN: "HND",
  HU: "HUN",
  IS: "ISL",
  IN: "IND",
  ID: "IDN",
  IR: "IRN",
  IQ: "IRQ",
  IE: "IRL",
  IM: "IMN",
  IL: "ISR",
  IT: "ITA",
  JM: "JAM",
  JP: "JPN",
  JE: "JEY",
  JO: "JOR",
  KZ: "KAZ",
  KE: "KEN",
  KI: "KIR",
  KP: "PRK",
  KR: "KOR",
  KW: "KWT",
  KG: "KGZ",
  LA: "LAO",
  LV: "LVA",
  LB: "LBN",
  LS: "LSO",
  LR: "LBR",
  LY: "LBY",
  LI: "LIE",
  LT: "LTU",
  LU: "LUX",
  MK: "MKD",
  MG: "MDG",
  MW: "MWI",
  MY: "MYS",
  MV: "MDV",
  ML: "MLI",
  MT: "MLT",
  MH: "MHL",
  MQ: "MTQ",
  MR: "MRT",
  MU: "MUS",
  YT: "MYT",
  MX: "MEX",
  FM: "FSM",
  MD: "MDA",
  MC: "MCO",
  MN: "MNG",
  ME: "MNE",
  MS: "MSR",
  MA: "MAR",
  MZ: "MOZ",
  MM: "MMR",
  NA: "NAM",
  NR: "NRU",
  NP: "NPL",
  NL: "NLD",
  AN: "ANT",
  NC: "NCL",
  NZ: "NZL",
  NI: "NIC",
  NE: "NER",
  NG: "NGA",
  NU: "NIU",
  NF: "NFK",
  MP: "MNP",
  NO: "NOR",
  OM: "OMN",
  PK: "PAK",
  PW: "PLW",
  PS: "PSE",
  PA: "PAN",
  PG: "PNG",
  PY: "PRY",
  PE: "PER",
  PH: "PHL",
  PN: "PCN",
  PL: "POL",
  PT: "PRT",
  PR: "PRI",
  QA: "QAT",
  RE: "REU",
  RO: "ROU",
  RU: "RUS",
  RW: "RWA",
  BL: "BLM",
  SH: "SHN",
  KN: "KNA",
  LC: "LCA",
  MF: "MAF",
  PM: "SPM",
  VC: "VCT",
  WS: "WSM",
  SM: "SMR",
  ST: "STP",
  SA: "SAU",
  SN: "SEN",
  RS: "SRB",
  SC: "SYC",
  SL: "SLE",
  SG: "SGP",
  SK: "SVK",
  SI: "SVN",
  SB: "SLB",
  SO: "SOM",
  ZA: "ZAF",
  GS: "SGS",
  SS: "SSD",
  ES: "ESP",
  LK: "LKA",
  SD: "SDN",
  SR: "SUR",
  SJ: "SJM",
  SZ: "SWZ",
  SE: "SWE",
  CH: "CHE",
  SY: "SYR",
  TW: "TWN",
  TJ: "TJK",
  TZ: "TZA",
  TH: "THA",
  TL: "TLS",
  TG: "TGO",
  TK: "TKL",
  TO: "TON",
  TT: "TTO",
  TN: "TUN",
  TR: "TUR",
  TM: "TKM",
  TC: "TCA",
  TV: "TUV",
  UG: "UGA",
  UA: "UKR",
  AE: "ARE",
  GB: "GBR",
  US: "USA",
  UM: "UMI",
  UY: "URY",
  UZ: "UZB",
  VU: "VUT",
  VE: "VEN",
  VN: "VNM",
  VI: "VIR",
  WF: "WLF",
  EH: "ESH",
  YE: "YEM",
  ZM: "ZMB",
  ZW: "ZWE",
  XK: "XKX",
};

export function getCountryPrettyName(
  str: string | undefined,
  isAprilFools = false
): string {
  const items = isAprilFools ? fictionalCountries : countries;
  if (str) {
    const country = items.find(
      (c) => sanitizeCountryName(c.name.toLowerCase()) === str
    );
    if (country) {
      return isAprilFools
        ? `${country.name}`
        : flag(country?.code)
        ? `${flag(country?.code)} ${country.name}`
        : `${country.name}`;
    }
  }
  return `${str}`;
}

export function formatTradeDistance(
  distanceInThousands: number | undefined
): string {
  if (distanceInThousands === undefined) {
    return "N/A";
  }

  const sign = Math.sign(distanceInThousands);
  const absDistance = Math.abs(distanceInThousands);

  const signChar = distanceInThousands > 0 ? "-" : "+";
  if (sign === 0) {
    return "🎉";
  } else if (absDistance >= 1e6) {
    return signChar + "$" + (absDistance / 1e6).toFixed(2) + "B";
  } else if (absDistance >= 1e3) {
    return signChar + "$" + (absDistance / 1e3).toFixed(2) + "M";
  } else {
    return signChar + "$" + absDistance.toFixed(2) + "K";
  }
}
